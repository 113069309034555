<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="toolbar-header">
        <transition name="fade-slide">
          <card-change-company
            class="mx-1"
            :selectedCompany="selectedCompany"
            :isHideSome="!isOnScrollTop"
            @refreshData="initialRender"
          />
        </transition>
        <div class="d-flex align-center px-2 my-2">
          <ion-buttons slot="start">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/sale/account"
              @click="goBack"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-3" @click="goBack">{{ $t('invoices_payment_history') }}</ion-label>
        </div>
        <ion-segment mode="ios" v-model="selectedSegment">
          <ion-segment-button value="INVOICES_SEGMENT">
            <ion-label>{{ $t('all_invoices') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="PAYMENT_HISTORY_SEGMENT">
            <ion-label>{{ $t('payment_history') }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content
      ref="contentRef"
      v-if="selectedSegment === 'INVOICES_SEGMENT'"
      :scroll-events="true"
      @ionScroll="handleScroll($event)"
    >
      <ion-refresher slot="fixed" @ionRefresh="handleRefreshAndRefetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>

      <comp-not-found
        v-if="isNotFoundInvoices"
        :headerLabel="$t('no_invoices')"
        :subHeaderLabel="$t('browse_your_business')"
        :actionLabel="$t('browse_product')"
        @callbackAction="goBrowseProduct"
      />
      <comp-banner-invoices
        ref="bannerInvoices"
        v-if="isRenderCompletePage"
        :totalUnpaidInvoices="totalUnpaidInvoices"
        :unpaidAmount="unpaidAmount"
        :currencySymbol="currencySymbol"
        :availableCreditAmount="availableCreditAmount"
        @onTopUpCreditToggle="setShowTopUpCredit(true)"
      />
      <div
        class="d-flex align-center px-4 py-2 bg-white sticky-top"
        v-if="isRenderCompletePage && selectedSegment === 'INVOICES_SEGMENT'"
      >
        <checkbox :checked="checkedAll" :disabled="isDisabledAllCheck()" @onToggle="onSelectAllInvoices" />
        <ion-label class="pl-2">{{ $t('select_all') }}</ion-label>
      </div>
      <div v-if="isRenderCompletePage">
        <card-invoices
          v-for="(invoice, i) in cardInvoices"
          :key="i"
          :invoice="{ ...invoice, tenantName }"
          @onSelection="onSelectSingleInvoice"
          @onEnterViewInvoice="onEnterViewInvoice(invoice)"
        />
      </div>
      <skeleton-card v-else-if="isLoadingSkeleton" />
      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledLoadMore"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
    <ion-content ref="contentRef" v-else :scroll-events="true" @ionScroll="handleScroll($event)">
      <ion-refresher slot="fixed" @ionRefresh="handleRefreshAndRefetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <comp-not-found
        v-if="paymentHistoryList.length === 0"
        :headerLabel="$t('not_found_payment_history_title')"
        :subHeaderLabel="$t('not_found_payment_history_desc')"
        :actionLabel="$t('go_homepage')"
        @callbackAction="goBrowseProduct"
      />
      <div v-if="paymentHistoryList.length >= 1 && isOnline">
        <card-payment-history-list
          v-for="(payment, i) in paymentHistoryList"
          :key="i"
          :data="payment"
          @onShowPaymentSummaryDetail="onShowPaymentSummaryDetail(payment)"
        />
      </div>
      <ion-infinite-scroll
        @ionInfinite="loadMorePaymentHistoriesList($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="false"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
    <comp-bottom-content
      v-if="isRenderCompletePage && selectedSegment === 'INVOICES_SEGMENT'"
      :currencySymbol="currencySymbol"
      :totalInvoiceAmount="totalInvoiceAmount"
      :totalSelectedInvoices="totalSelectedInvoices"
      @handle-pay-invoices="handlePayInvoices"
    />

    <skeleton-bottom v-else-if="isLoadingSkeleton" />
    <ion-modal mode="ios" :is-open="isOpenViewInvoice" @didDismiss="setOpenViewInvoice(false)">
      <modal-view-invoice
        :currencySymbol="currencySymbol"
        :invoiceId="invoiceSelected.id"
        @close-page="setOpenViewInvoice(false)"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      :initial-breakpoint="0.8"
      :breakpoints="[0, 0.5, 0.8, 1]"
      :is-open="isOpenModalSummaryDetail"
      @didDismiss="setOpenModalSummaryDetail(false)"
    >
      <modal-payment-history-detail
        ref="modalPaymentHistoryDetailRef"
        :data="paymentSummaryDetailData"
        @closeModal="setOpenModalSummaryDetail(false)"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      :initial-breakpoint="0.7"
      :is-open="isShowTopUpCredit"
      :backdropDismiss="true"
      @didDismiss="setShowTopUpCredit(false)"
      class="modal-radius-md"
    >
      <modal-top-up-credit
        ref="modalTopUpRef"
        @onTopUpApplied="onTopUpApplied"
        @closeModal="setShowTopUpCredit(false)"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      css-class="modal-bottom-sheet-custom-action"
      :is-open="isOpenModalSummaryDetail"
      :initial-breakpoint="1"
      :breakpoints="[1]"
      @didDismiss="setOpenModalSummaryDetail(false)"
      :backdrop-breakpoint="1"
      :handle="false"
    >
      <ion-grid class="mb-5">
        <ion-row>
          <ion-col>
            <ion-button
              v-if="
                [PENDING_APPROVAL].includes(paymentSummaryDetailData?.payment_status) &&
                activeUser.id === paymentSummaryDetailData.user_id
              "
              fill="outline"
              @click="onCancelFromPaymentHistoryDetail"
              expand="block"
              color="danger"
              class="rounded-sm"
            >
              <ion-label class="text-capitalize"> {{ $t('cancel_payment') }} </ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button
              class="rounded-sm"
              @click="setOpenModalSummaryDetail(false)"
              fill="outline"
              expand="block"
            >
              <ion-label class="text-capitalize"> {{ $t('close') }} </ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-modal>
  </ion-page>
</template>
<script>
// packages
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { PAYMENT_HISTORY_LIST_STATUS } from '@/modules/shared/constants/';
import { useAlert } from '@/modules/shared/utils/';
import { useBackButton } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { defineAsyncComponent, defineComponent, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers, useStore } from 'vuex';

// components
import Checkbox from '@/components/Checkbox.vue';
import ModalViewInvoice from '@/modules/sale/views/order/components/Invoice.vue';
import CardChangeCompany from '@/modules/shared/components/CardChangeCompany.vue';

import {
  CardInvoices,
  CardPaymentHistoryList,
  CompBannerInvoices,
  CompBottomContent,
  ModalPaymentHistoryDetail,
  ModalTopUpCredit,
  SkeletonBottom,
  SkeletonCard
} from '@/modules/shared/components/invoices-payment';

const { mapGetters, mapActions } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'select-invoices-sale',
  inject: ['$storage'],
  components: {
    ModalTopUpCredit,
    CompBottomContent,
    CardInvoices,
    Checkbox,
    SkeletonCard,
    SkeletonBottom,
    CompBannerInvoices,
    CardChangeCompany,
    CardPaymentHistoryList,
    ModalViewInvoice,
    ModalPaymentHistoryDetail,
    CompNotFound: defineAsyncComponent(() => import('@/modules/shared/components/CompNotFound.vue')),
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { createAlert } = useAlert();
    const isOpenRefLoading = ref(false);
    const cardInvoices = ref([]);
    const selectedSegment = ref('INVOICES_SEGMENT');
    const setOpenLoading = (state) => (isOpenRefLoading.value = state);
    const goBrowseProduct = () => {
      router.push({ path: '/sale/main/home' }, () => {}, { replace: true });
    };
    const goBack = () => {
      store.dispatch(`sale/payment/${ACTIONS.REMOVE_SELECTED_PAYMENT_INVOICES}`);
      const stateBackPath = router.options.history.state.back;
      nextTick(() => {
        stateBackPath === '/sale/account' ||
        stateBackPath === '/sale/select-customer' ||
        stateBackPath === '/sale/invoices/select-invoices' ||
        stateBackPath === '/sale/invoices/select-payment'
          ? router.push({ path: '/sale/account' }, () => {}, { replace: true })
          : router.back();
      });
    };
    const modalTopUpRef = ref(null);
    const isOpenModalSummaryDetail = ref(false);
    const setOpenModalSummaryDetail = (state) => (isOpenModalSummaryDetail.value = state);
    const checkedAll = ref(true);
    const isOpenViewInvoice = ref(false);
    const setOpenViewInvoice = (state) => (isOpenViewInvoice.value = state);
    const invoiceSelected = ref(null);
    const isOnScrollTop = ref(false);
    const bannerInvoices = ref(null);
    const paymentSummaryDetailData = ref(null);
    const { PAID, PENDING_APPROVAL } = PAYMENT_HISTORY_LIST_STATUS;
    const onShowPaymentSummaryDetail = (data) => {
      paymentSummaryDetailData.value = data;
      setOpenModalSummaryDetail(true);
    };
    const isOnline = ref(true);
    const detectConnection = () => {
      const connection = navigator.onLine;
      if (connection) {
        isOnline.value = true;
      } else {
        isOnline.value = false;
      }
    };
    const handleRefresh = async (event) => {
      await detectConnection();
      event.target.complete();
    };
    const isShowTopUpCredit = ref(false);
    const setShowTopUpCredit = (state) => {
      isShowTopUpCredit.value = state;
      if (isShowTopUpCredit.value) {
        setTimeout(() => {
          modalTopUpRef.value.loadFocus();
        }, 500);
      }
    };
    const onTopUpApplied = async (paramsTopUpCredit) => {
      setShowTopUpCredit(false);
      await store.dispatch(`sale/payment/${ACTIONS.SET_PARAMS_TOP_UP_CREDIT}`, {
        paramsTopUpCredit
      });
      router.push('/sale/invoices/select-payment');
    };
    useBackButton(10, async () => {
      await goBack();
    });
    return {
      modalTopUpRef,
      isShowTopUpCredit,
      setShowTopUpCredit,
      onTopUpApplied,
      isOpenModalSummaryDetail,
      setOpenModalSummaryDetail,
      onShowPaymentSummaryDetail,
      isOpenRefLoading,
      setOpenLoading,
      chevronBackOutline,
      goBrowseProduct,
      goBack,
      checkedAll,
      activeUser: ref(null),
      selectedCompany: ref(null),
      cardInvoices,
      selectedCardInvoices: ref([]),
      totalInvoiceAmount: ref(0),
      totalSelectedInvoices: ref(0),
      isDisabledLoadMore: ref(false),
      tenantName: ref(''),
      bannerInvoices,
      setOpenViewInvoice,
      isOpenViewInvoice,
      invoiceSelected,
      createAlert,
      selectedSegment,
      isOnScrollTop,
      paymentSummaryDetailData,
      isLoadMoreInvoices: ref(false),
      handlePayInvoices: () => {
        let path = '/sale/invoices/select-payment';
        router.push(path);
      },
      onEnterViewInvoice: (invoiceArg) => {
        invoiceSelected.value = invoiceArg;
        setOpenViewInvoice(true);
      },
      handleScroll: debounce((e) => {
        const onScrollHeightValue = e.detail.scrollTop;
        const bannerHeightVisibilityValue =
          selectedSegment.value === 'INVOICES_SEGMENT' ? bannerInvoices.value.$el.scrollHeight : 10;
        if (onScrollHeightValue > bannerHeightVisibilityValue) isOnScrollTop.value = true;
        if (onScrollHeightValue === 0) isOnScrollTop.value = false;
      }, 200),
      isOnline,
      handleRefresh,
      PAID,
      PENDING_APPROVAL
    };
  },
  watch: {
    'listInvoices.invoices': {
      handler: function () {
        if (!this.isLoadMoreInvoices) {
          this.setCheckAllInvoices();
          this.initCardInvoices();
          this.handleSelectedInvoice();
        }
      },
      deep: true
    },
    paymentHistoryList: {
      handler: function () {
        if (this.paymentSummaryDetailData) {
          this.paymentSummaryDetailData = this.paymentHistoryList.find(
            (item) => item.id === this.paymentSummaryDetailData.id
          );
        }
      },
      deep: true
    },
    selectedSegment: function () {
      this.isOnScrollTop = false;
      this.$refs.contentRef.$el.scrollToTop(500);
    }
  },
  computed: {
    ...mapGetters([
      'listInvoices',
      'status',
      'error',
      'totalCountInvoices',
      'xeroCustomer',
      'totalUnpaidInvoices',
      'isFinanceModuleEnabled',
      'paymentHistoryList'
    ]),
    isLoadingSkeleton() {
      return this.isOpenRefLoading;
    },
    isNotFoundInvoices() {
      return (
        (!this.isOpenRefLoading && this.cardInvoices.length === 0) ||
        (!this.isOpenRefLoading && !this.isFinanceModuleEnabled)
      );
    },
    isRenderCompletePage() {
      return (
        !this.isOpenRefLoading &&
        this.cardInvoices.length >= 1 &&
        this.isFinanceModuleEnabled &&
        this.isOnline
      );
    },
    currencySymbol() {
      return this.activeUser && this.activeUser.currency_symbol;
    },
    availableCreditAmount() {
      const credit =
        Number(this.xeroCustomer?.overpayment_amount || 0) +
        Number(this.xeroCustomer?.credit_notes_amount || 0);
      return credit > 0 ? credit : 0;
    },
    unpaidAmount() {
      return Number(this.xeroCustomer?.outstanding_amount || 0);
    }
  },
  async mounted() {
    await this.initialRender();
  },
  async ionViewDidEnter() {
    if (!this.$router.options.history.state.forward) {
      await this.initialRender();
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_CUSTOMER_INVOICES,
      ACTIONS.RESET_STATUS_ERROR,
      ACTIONS.LOAD_MORE_CUSTOMER_INVOICES,
      ACTIONS.GET_XERO_CUSTOMER,
      ACTIONS.GET_TOTAL_UNPAID_INVOICES,
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED,
      ACTIONS.GET_PAYMENT_HISTORY_LIST,
      ACTIONS.LOAD_MORE_PAYMENT_HISTORY_LIST,
      ACTIONS.RESET_PARAMS_TOP_UP_CREDIT
    ]),
    async initialRender() {
      this.setOpenLoading(true);
      this.selectedSegment = 'INVOICES_SEGMENT';
      this.activeUser = await this.$storage.getUser();
      this.selectedCompany = await this.$storage.getSelectedCompany();
      this.tenantName = this.activeUser.tenant.tenant.name;
      await this.checkIsFinanceModuleEnabled();
      if (!this.isFinanceModuleEnabled) {
        this.setOpenLoading(false);
        return;
      }
      await this[ACTIONS.RESET_PARAMS_TOP_UP_CREDIT]();
      await this.getXeroCustomer();
      await this.getTotalUnpaidInvoices();
      await this.getInvoices();
      await this.getPaymentHistoryList();
      this.isDisabledLoadMore = false;
      this.setOpenLoading(false);
    },
    async handleRefreshAndRefetchData(event) {
      await this.handleRefresh(event);
      if (this.isOnline) {
        if (this.selectedSegment === `INVOICES_SEGMENT`) {
          if (!this.isLoadMoreInvoices) {
            await this.getXeroCustomer();
            await this.getTotalUnpaidInvoices();
            await this.getInvoices();
          }
        } else {
          await this.getPaymentHistoryList();
        }
      }
    },
    async getPaymentHistoryList() {
      await this[ACTIONS.GET_PAYMENT_HISTORY_LIST]({
        paramsPaymentHistoryList: {
          tenant_id: this.activeUser.tenant.id,
          customer_id: this.selectedCompany.id,
          offset: 0,
          isIncludeTotalCount: true
        }
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
          return;
        }
      });
    },
    async checkIsFinanceModuleEnabled() {
      await this[ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({
        tenantId: this.activeUser.tenant.id,
        customerId: this.selectedCompany.id
      });
    },

    async getXeroCustomer() {
      await this[ACTIONS.GET_XERO_CUSTOMER]({
        tenantId: this.activeUser.tenant.id,
        customerId: this.selectedCompany.id
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
        }
      });
    },
    async getTotalUnpaidInvoices() {
      await this[ACTIONS.GET_TOTAL_UNPAID_INVOICES]({
        tenantId: this.activeUser.tenant.id,
        customerId: this.selectedCompany.id
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
        }
      });
    },
    async getInvoices() {
      await this[ACTIONS.GET_CUSTOMER_INVOICES]({
        paramsListInvoices: {
          tenantId: this.activeUser.tenant.id,
          customerId: this.selectedCompany.id,
          offset: 0,
          isIncludeTotalCount: true
        }
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
          return;
        }
        this.setCheckAllInvoices();
        this.initCardInvoices();
        this.handleSelectedInvoice();
      });
    },
    handleApiError(errorMessage) {
      this[ACTIONS.RESET_STATUS_ERROR]();
      this.createAlert(this.$t('failed'), errorMessage, this.handleRefresh);
    },
    loadMore(e) {
      try {
        if (this.listInvoices.invoices.length === this.totalCountInvoices) {
          this.isDisabledLoadMore = true;
          this.isLoadMoreInvoices = false;
          return;
        }
        this.isLoadMoreInvoices = true;
        this[ACTIONS.LOAD_MORE_CUSTOMER_INVOICES]({
          paramsListInvoices: {
            tenantId: this.activeUser.tenant.id,
            customerId: this.selectedCompany.id,
            offset: this.listInvoices.invoices.length,
            isIncludeTotalCount: false
          },
          onFinish: async () => {
            await this.getTotalUnpaidInvoices();
            await this.initCardInvoices();
            await this.handleSelectedInvoice();
            this.isLoadMoreInvoices = false;
            e.target.complete();
          }
        });
      } catch (error) {
        this.handleApiError(error.message);
      }
    },
    async loadMorePaymentHistoriesList(e) {
      await this[ACTIONS.LOAD_MORE_PAYMENT_HISTORY_LIST]({
        paramsPaymentHistoryList: {
          tenant_id: this.activeUser.tenant.id,
          customer_id: this.selectedCompany.id,
          offset: this.paymentHistoryList.length,
          isIncludeTotalCount: true
        },
        onFinish: async () => {
          e.target.complete();
        }
      });
    },
    setCheckAllInvoices() {
      this.checkedAll = this.listInvoices.invoices.some(
        (item) => item.outstanding_amount > 0 && !item.is_pending_approval
      );
    },
    initCardInvoices() {
      if (!this.listInvoices.invoices) return;
      this.cardInvoices = this.listInvoices.invoices.map((i) => {
        const outstandingAmount = i.outstanding_amount;
        const isPaid = outstandingAmount === 0;
        const isDisabled = isPaid || i.is_pending_approval;
        let isSelected = false;
        if (!this.checkedAll) {
          isSelected =
            this.cardInvoices.find((item) => item.id === i.id && item.isSelected)?.isSelected || false;
        }
        return {
          id: i.id,
          customer: i.company_name,
          invoiceNumber: i.invoice_number,
          currencySymbol: this.currencySymbol,
          dueDate: i.due_date,
          invoiceDate: i.actual_delivery_date,
          amount: i.amount,
          outstandingAmount: outstandingAmount,
          creditTerm: i.credit_term,
          isPendingApproval: i.is_pending_approval,
          isPaid: isPaid,
          isSelected: !this.checkedAll ? isSelected : !isDisabled,
          isDisabled: isDisabled,
          creditTermName: i.credit_term_display_name,
          paymentTypeId: i.payment_type_id,
          isOverdue: i.is_overdue,
          isDueToday: i.is_due_today
        };
      });
    },
    thereIsUnpaidInvoices() {
      const unpaidInv = this.cardInvoices.filter((i) => !i.isPaid && !i.isPendingApproval);
      return unpaidInv.every((i) => i.isSelected);
    },
    isDisabledAllCheck() {
      return this.cardInvoices.every(({ isDisabled }) => isDisabled);
    },
    onSelectAllInvoices(checked) {
      if (checked) {
        this.cardInvoices = this.cardInvoices.map((i) => {
          if (!i.isDisabled) i.isSelected = true;
          return i;
        });
      } else {
        if (this.thereIsUnpaidInvoices()) {
          this.cardInvoices = this.cardInvoices.map((i) => {
            i.isSelected = false;
            return i;
          });
        }
      }
    },
    async onSelectSingleInvoice(invoiceId, checked) {
      const invoiceIdx = this.cardInvoices.findIndex((i) => i.id === invoiceId);
      if (this.cardInvoices[invoiceIdx]) {
        this.cardInvoices[invoiceIdx].isSelected = checked;
        if (this.thereIsUnpaidInvoices()) this.checkedAll = true;
        else this.checkedAll = false;
        await this.handleSelectedInvoice();
      }
    },
    async handleSelectedInvoice() {
      const selectedInvoices = this.cardInvoices.filter((i) => !i.isDisabled && i.isSelected);
      const totalInvoiceAmount = selectedInvoices.reduce((acc, i) => acc + i.outstandingAmount, 0);
      const totalSelectedInvoices = selectedInvoices.length;
      this.selectedCardInvoices = [...selectedInvoices];
      this.totalInvoiceAmount = totalInvoiceAmount;
      this.totalSelectedInvoices = totalSelectedInvoices;
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedCardInvoices,
        totalInvoiceAmount: +totalInvoiceAmount.toFixed(2),
        availableCreditAmount: this.availableCreditAmount
      });
    },
    onCancelFromPaymentHistoryDetail() {
      this.$refs.modalPaymentHistoryDetailRef.onCancelPayment();
    }
  }
});
</script>
<style lang="scss" scoped>
ion-segment {
  background-color: #fff !important;
}
ion-segment-button::part(indicator-background) {
  display: none;
}
ion-segment-button.ios::part(indicator-background) {
  display: none;
}
// ios styles
ion-segment-button.ios::part(native) {
  background: #fff;
  border-bottom: 2px solid rgb(169, 169, 169);
}

.segment-button-checked.ios::part(native) {
  color: var(--ion-color-text-primary-green-600);
  border-bottom: 2px solid var(--ion-color-text-primary-green-600);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}
.rounded-sm {
  --border-radius: 10px;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.fade-slide-enter, .fade-slide-leave-to /* .fade-slide-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}

ion-content {
  scroll-behavior: smooth;
}
</style>
